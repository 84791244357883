import { Component, OnInit, WritableSignal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAccountSettings, IUser } from '@core/interfaces/iuser';
import { take } from 'rxjs';
import { InputType } from '@core/enums/input-type';
import { IDistricts, IGrades } from '@core/interfaces/ilookup';
import { AuthService } from '@core/services/auth.service';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { UserManagementService } from '../management/services/user-management.service';
import { Role } from '@core/enums/roles';

@Component({
  selector: 'prism-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrl: './account-settings.component.scss'
})
export class AccountSettingsComponent implements OnInit {
  currentUser: WritableSignal<ILoginResponse | null> = this.authService.currentUserInfo;
  settingsForm: FormGroup | any;
  userSettings!: IAccountSettings;
  isEditable: boolean = false;
  userInfo?: ILoginResponse | any;
  isSuperAdmin: boolean = this.currentUser()?.roleId === Role.SystemAdmin;

  organizationOptions: IDistricts[] = [];
  gradeOptions: IGrades[] = [];
  inputType = InputType;
  roles = Role;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.userInfo = {...this.currentUser()};
    this.isEditable = this.currentUser()?.roleId != Role.User;
    this.initForm();
    this.updateForm();
  }


  initForm(): void {
    this.settingsForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.maxLength(50)]],
      lastName: [null, [Validators.required, Validators.maxLength(50)]],
      districtId: [null, this.isEditable ? null : Validators.required],
      emailName: [null, Validators.required],
      gradeId: [null, this.isEditable ? null : Validators.required]
    });
  }

  updateForm(): void {
    this.settingsForm.patchValue({
      ...this.currentUser()
    });
  }

  saveChanges(): void {
    const payload: IUser = {...this.settingsForm.value};
    payload.id = this.userInfo.userId;
    payload.roleId = this.userInfo.roleId;
    this.userService.editUser(payload, "Updated profile successfully")
      .pipe(take(1)).subscribe((res: boolean) => {
        if (res) {
          this.currentUser.update(value =>
            {
              const update: any = { ...value, ...payload };
              update.fullName =  payload.firstName + ' ' + payload.lastName;
              localStorage.setItem('prism-user', JSON.stringify(update));
              return update;
            }
          )
        }
      })
  }
}
