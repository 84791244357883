
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesRoutingModule } from './features-routing.module';
import { UserManagementComponent } from './management/components/user-management/user-management.component';
import { SharedModule } from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCardModule } from 'ng-zorro-antd/card';
import { ReactiveFormsModule } from '@angular/forms';
import { UserAddComponent } from './management/components/user-management/user-add/user-add.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { UserFormComponent } from './management/components/user-management/user-form/user-form.component';
import { UserUploadComponent } from './management/components/user-management/user-upload/user-upload.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule } from "ng-recaptcha";
import { environment } from '@env/environment';
import { UserValidator } from '@core/validators/user.validator';
import { ResourcesComponent } from './resources/components/resources.component';
import { ExternalResourcesComponent } from './resources/components/external-resources/external-resources.component';
import { ResourcesFormComponent } from './resources/components/resources-form/resources-form.component';
import { ResourceViewComponent } from './resources/components/resource-view/resource-view.component';
import { CourseComponent } from './course/course.component';
import { ImpactVideoComponent } from './resources/components/impact-video/impact-video.component';
import { QuillModule } from 'ngx-quill';
import { CourseHistoryComponent } from './courses/components/course-history/course-history.component';
import { CourseAttachmentComponent } from './course/course-attachment/course-attachment.component';
import { CourseUserComponent } from './courses/components/course-user/course-user.component';
import { CourseViewComponent } from './course/course-view/course-view.component';
import { DateFormatPipe } from "../../core/pipes/date-format.pipe";
import { ReportComponent } from './report/report.component';
import { ReportCardComponent } from './report/report-card/report-card.component';
import { ReportCardsComponent } from './report/report-cards/report-cards.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ReportViewComponent } from './report/report-view/report-view.component';
import { UrlValidator } from '@core/validators/url.validator';
import { CourseEditComponent } from './course/course-edit/course-edit.component';
import { DistrictManagementComponent } from './management/components/district-management/district-management.component';
import { SchoolManagementComponent } from './management/components/school-management/school-management.component';

@NgModule({
    declarations: [
        UserManagementComponent,
        DashboardComponent,
        AccountSettingsComponent,
        UserAddComponent,
        UserFormComponent,
        UserUploadComponent,
        CourseComponent,
        ExternalResourcesComponent,
        ResourcesComponent,
        ResourcesFormComponent,
        ResourceViewComponent,
        ImpactVideoComponent,
        CourseHistoryComponent,
        CourseAttachmentComponent,
        CourseUserComponent,
        CourseViewComponent,
        CourseEditComponent,
        ReportComponent,
        ReportCardComponent,
        ReportCardsComponent,
        ReportViewComponent,
        DistrictManagementComponent,
        SchoolManagementComponent
    ],
    providers: [
        UserValidator,
        UrlValidator,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },
        DateFormatPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    imports: [
        CommonModule,
        FeaturesRoutingModule,
        SharedModule,
        CoreModule,
        NzModalModule,
        ReactiveFormsModule,
        NzTabsModule,
        NzCardModule,
        RecaptchaV3Module,
        RecaptchaModule,
        QuillModule.forRoot(),
        DateFormatPipe,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: "#78C000",
            innerStrokeColor: "#C7E596",
            animationDuration: 300
          })
    ]
})
export class FeaturesModule { }
