import { Injectable, inject } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { IDistrict, ISearchDistrictRequest } from '../interfaces/isearchDistrict';

@Injectable({
  providedIn: 'root'
})
export class DistrictManagementService {
  dataService = inject(DataService);

  searchDistricts(payload: ISearchDistrictRequest) {
    return this.dataService.post(`district/search`, payload);
  }

  addDistrict(payload: IDistrict, message: string) {
    return this.dataService.post(`district`, payload, message);
  }

  updateDistrict(payload: IDistrict, message: string) {
    return this.dataService.put(`district`, payload, message);
  }

  deleteDistrict(id: number) {
    return this.dataService.delete(`district?id=${id}`);
  }
}
