<div class="max-h-fit px-10 lg:px-20">
    <prism-title title="Districts"></prism-title>
    <div class="grid grid-cols-2 py-2">
      <div class="w-full lg:w-3/4">
      @if (!isDistrictAdmin) {
          <prism-input 
            inputType="text" 
            [control]="searchForm.get('search')"
            [required]="false"
            inputType="text"
            placeHolder="Search districts"
            [hasSearch]="true"
            (search)="getDistricts()">
          </prism-input>
      }
    </div>
      <div class="pb-4 flex justify-end">
        <prism-btn 
            btnClass="bg-white text-dark-purple font-bold border-2  p-0 m-0 rounded-xl border-dark-purple"
            (click)="addDistrict()">
                <i class="uil uil-plus text-dark-purple font-bold pr-1"></i>Add District
        </prism-btn>
      </div>
    </div>
    <div>
        <prism-table [columns]="columns" 
            [colHeaders]="colHeaders" 
            [tableData]="data" 
            [isDelete]="true" 
            [isEdit]="true"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [totalData]="totalData"
            (edit)="editDistrict($event)"
            (pageIndexChange)="handlePageIndexChange($event)"
            (pageSizeChange)="handlePageSizeChange($event)"
            (delete)="deleteDistrict($event)"
            (navigate)="navigate($event)"
            [isDistrictTable]="true">
        </prism-table>
    </div>
</div>
<nz-modal 
  [(nzVisible)]="isAddEditDistrict" 
  [nzTitle]="modalTitle" 
  (nzOnCancel)="handleCancel()" 
  [nzFooter]="null">
  <ng-container *nzModalContent>
    <form [formGroup]="districtForm" class="grid gap-4" (ngSubmit)="submit()">
        <prism-input
          [control]="name"
          label="District Name"
          placeHolder="District Name"
          [required]="true"
          [inputType]="InputType.Text">
        </prism-input>
        <div class="col-span-1">
          <prism-select-county
            [control]="countyId"
            label="County"
            [readOnly]="isDistrictAdmin">
          </prism-select-county>
        </div>
        <hr class="-mx-6">
        <div class="flex items-center">
          <prism-btn [disabled]="!districtForm.valid">Save</prism-btn>
        </div>
    </form>
  </ng-container>
</nz-modal>