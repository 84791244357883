export enum DistrictHeaders {
    name = 'Name',
    schoolCount = 'Number of Schools',
    countyName = 'County Name'
  }
  
  export enum DistrictProps {
    'Name' = 'Name',
    'Schools' = 'SchoolCount',
    'County Name' = 'CountyName'
  }
  