import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Tabs } from '../../../enums/tabs';

@Component({
  selector: 'prism-user-add',
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.scss',
  encapsulation: ViewEncapsulation.None 
})
export class UserAddComponent implements OnInit {
  tabs = [Tabs.AddAUser, Tabs.BulkAddUser];

  ngOnInit(): void {}
}
