import { inject, Injectable } from '@angular/core';
import { DataService } from '@core/services/data.service';
import { ISchool, ISearchSchoolRequest } from '../interfaces/isearchSchool';

@Injectable({
  providedIn: 'root'
})
export class SchoolManagementService {
  dataService = inject(DataService);

  searchSchools(payload: ISearchSchoolRequest) {
    return this.dataService.post(`school/search`, payload);
  }

  addSchool(payload: ISchool, message?: string) {
    return this.dataService.post(`school`, payload, message);
  }

  updateSchool(payload: ISchool, message?: string) {
    return this.dataService.put(`school`, payload, message);
  }

  deleteSchool(id: number) {
    return this.dataService.delete(`school?id=${id}`);
  }
}
