import { Component, ElementRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchoolManagementService } from '../../services/school-management.service';
import { AuthService } from '@core/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastService } from '@core/services/toast.service';
import { LookupService } from '@core/services/lookup.service';
import { Role } from '@core/enums/roles';
import { IDistricts } from '@core/interfaces/ilookup';
import { distinctUntilChanged, take, tap } from 'rxjs';
import { SchoolHeaders, SchoolProps } from '../../enums/school-headers';
import { ISchool, ISearchSchoolRequest, ISearchSchoolResponse } from '../../interfaces/isearchSchool';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prism-school-management',
  templateUrl: './school-management.component.html',
  styleUrl: './school-management.component.scss'
})
export class SchoolManagementComponent {
    private schoolService = inject(SchoolManagementService);
    private route = inject(ActivatedRoute);
    private authService = inject(AuthService);
    private lookupService= inject(LookupService);
    private toastService= inject(ToastService);
    private modal= inject(NzModalService);
    private fb = inject(FormBuilder);
    columns = ['name'];
    data: any[] = [];
    colHeaders = SchoolHeaders;
    SchoolProps = SchoolProps;
    isVisible = false;
    schoolForm!: FormGroup;
    searchForm!: FormGroup;
    organizationOptions: IDistricts[] = [];
    modalTitle = 'Add School';
    pageIndex = 1;
    pageSize = 10;
    totalData = 0;
    currentUser = this.authService.getCurrentUser();
    isDistrictAdmin = this.currentUser.roleId === Role.DistrictAdmin;
    routeDistrictId?: string | null;
    get name() {
      return this.schoolForm.get('name');
    }
    get districtId() {
      return this.schoolForm.get('districtId');
    }
    get search() {
      return this.searchForm.get('search');
    }
    get organizationId() {
      return this.searchForm.get('organizationId');
    }

  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {
    this.initializeForms();
    this.searchSchools();
    this.getOrganizations();
    this.listenOrganization();

    // Check for districtId in route params
    this.route.paramMap.subscribe(params => {
      this.routeDistrictId = params.get('districtId');
      if (this.routeDistrictId) {
        this.searchForm.patchValue({ organizationId: +this.routeDistrictId });
      }
      this.searchSchools();
    });
  }

  initializeForms(): void {
    this.schoolForm = this.fb.group({
      id: [0],
      name: [null, [Validators.required, Validators.maxLength(150)]],
      districtId: [null, Validators.required],
    });

    this.searchForm = this.fb.group({
      search: [''],
      organizationId: [this.currentUser.roleId === Role.DistrictAdmin ? this.routeDistrictId ?? this.currentUser.districtId : null]
    });
  }

  getOrganizations(): void {
    this.lookupService.getOrganizations().pipe(
      take(1),
      tap((res: IDistricts[]) => this.organizationOptions = res)
    ).subscribe();
  }

  searchSchools() {
    let payload: ISearchSchoolRequest = {
      ...this.searchForm.value,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize
    };

    this.schoolService.searchSchools(payload).pipe(
      take(1)
    ).subscribe((res: ISearchSchoolResponse) => {
      this.data = res.items;
      this.pageIndex = res.pageIndex;
      this.totalData = res.totalData;
    })
  }

  addSchool() {
    this.modalTitle = 'Add School';
    this.schoolForm.patchValue({
      districtId: this.currentUser.roleId === Role.DistrictAdmin ? this.currentUser.districtId : null
    });
    this.isVisible = true;
  }

  editSchool(school: ISchool) {
    console.log(school);
    this.modalTitle = 'Edit School';
    this.schoolForm.patchValue({
      ...school
    });
    this.isVisible = true;
  }

  deleteSchool(school: ISchool) {
    this.modal.confirm({
      nzTitle: `Delete School: ${school.name}`,
      nzContent: 'Are you sure you want to delete this school?',
      nzOkText: 'Yes',
      nzIconType: "exclamation-circle",
      nzOnOk: () => {
        this.schoolService.deleteSchool(school.id).pipe(
          tap((res: boolean) => {
            if (res) {
              this.toastService.success('School deleted successfully');
              this.searchSchools();
            }
          })
        ).subscribe();
      },
      nzCancelText: 'No',
    });
  }


  listenOrganization(): void {
    this.organizationId?.valueChanges.pipe(
      distinctUntilChanged(),
      tap((value: number) => {
        this.search?.setValue(null);
        this.organizationId?.setValue(value);
        this.searchSchools();
      })
    ).subscribe();

    this.districtId?.valueChanges.subscribe(() => {
      const districtIdControl = this.districtId;
      if (districtIdControl && districtIdControl.hasError('required')) {
          if (districtIdControl.value !== null && districtIdControl.value !== '') {
              districtIdControl.setErrors(null);
          }
      }
    });
  }

  handlePageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.searchSchools();
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.searchSchools();
  }

  handleOk(): void {
    if (this.schoolForm.invalid) {
      this.toastService.error('Please fill out all required fields');
      return;
    }
    const payload: ISchool = {
      ...this.schoolForm.value
    }

    if(payload.id > 0) {
      this.schoolService.updateSchool(payload).pipe(
        take(1),
        tap((res: boolean) => {
          if (res) {
            this.searchSchools();
            this.toastService.success('School updated successfully');
          }
          this.isVisible = false;
        })
      ).subscribe();
    } else {
      this.schoolService.addSchool(payload).pipe(
        take(1),
        tap((res: boolean) => {
          if (res) {
            this.searchSchools();
            this.toastService.success('School added successfully');
          }
          this.isVisible = false;
        })
      ).subscribe();
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }
}
