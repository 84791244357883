import { Component, ElementRef, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Role } from '@core/enums/roles';
import { IDistricts } from '@core/interfaces/ilookup';
import { LookupService } from '@core/services/lookup.service';
import { UserManagementService } from '@views/features/management/services/user-management.service';
import { take, tap } from 'rxjs';
import { ReportType } from '../enums/report-type';
import { AuthService } from '@core/services/auth.service';
import { ReportService } from '../services/report.service';
import { ISearchReportResponse } from '../interfaces/ireport-response';
import { ReportHeaders } from '../enums/report-headers';
import { IReportRequest } from '../interfaces/ireport-request';
import { IUser } from '@core/interfaces/iuser';
import { DateFormatPipe } from '@core/pipes/date-format.pipe';

@Component({
  selector: 'prism-report-view',
  templateUrl: './report-view.component.html',
  styleUrl: './report-view.component.scss'
})
export class ReportViewComponent {
  userManagementService = inject(UserManagementService);
    private lookupService= inject(LookupService);
    private route = inject(ActivatedRoute);
    private authService = inject(AuthService);
    private reportService = inject(ReportService);
    private dateFormatter = inject(DateFormatPipe);
    private fb = inject(FormBuilder);
    columns = ['fullName', 'courseName', 'grade', 'status', 'organizationName', 'completedOn'];
    data: any[] = [];
    colHeaders = ReportHeaders;
    userForm!: FormGroup;
    organizationOptions: IDistricts[] = [];
    pageIndex = 1;
    pageSize = 10;
    totalData = 0;
    reportType = '';
    pageTitle = 'Report';
    searchForm!: FormGroup;
    user: IUser = this.authService.getCurrentUser();
    role = Role;

    get search() {
      return this.searchForm.get('search');
    }
    get organization() {
      return this.searchForm.get('organization');
    }
    get isCompleted() {
      return this.searchForm.get('isCompleted');
    }

  constructor(private eRef: ElementRef) {
    this.route.queryParams.subscribe(params => {
      this.reportType = params['type'];

      switch (this.reportType) {
        case ReportType.Completed:
          this.pageTitle = 'Completed Report';
          break;
        case ReportType.Progress:
          this.pageTitle = 'Progress Report';
          break;
        default:
          this.pageTitle = 'District Report';
          break;
      }
    });
  }

  ngOnInit(): void {
    this.initializeForm();
    this.searchReport();
    this.getOrganizations();
    this.listenOrganization();
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      search: [''],
      organization: [null],
      isCompleted: [null]
    });

    if (this.user?.roleId == Role.DistrictAdmin) {
      this.organization?.setValue(this.user?.districtId);
    }

    if (this.reportType == ReportType.Completed) {
      this.isCompleted?.setValue(true);
    }

    if (this.reportType == ReportType.Progress) {
      this.isCompleted?.setValue(false);
    }
  }

  getOrganizations(): void {
    this.lookupService.getOrganizations().pipe(
      take(1),
      tap((res: IDistricts[]) => this.organizationOptions = res)
    ).subscribe();
  }


  listenOrganization(): void {
    this.organization?.valueChanges.pipe(
      tap((value: number) => {
        const searchOrganization = this.organizationOptions.find((org: IDistricts) => org.id === value);
        this.search?.setValue(searchOrganization?.name);
        this.searchReport();
      })
    ).subscribe();
  }

  handlePageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.searchReport();
  }

  handlePageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.searchReport();
  }

  searchReport(): void {
    const payload: IReportRequest = {
      ...this.searchForm.value,
      pageIndex: this.pageIndex,
      ItemsPerPage: this.pageSize
    }

    this.reportService.searchReport(payload).pipe(
      take(1),
      tap((res: ISearchReportResponse) => {
        this.data = res.items.map((item: any) => {
          return {
            ...item,
            completedOn: item.completedOn ? this.dateFormatter.transform(item.completedOn) : ''
          }
        });
        this.pageIndex = res.pageIndex;
        this.totalData = res.totalData;
      })
    ).subscribe();
  }

  downloadReport() {
    const payload: IReportRequest = {
      ...this.searchForm.value,
      pageIndex: this.pageIndex,
      ItemsPerPage: this.pageSize
    }
    this.reportService.downloadReport(payload).pipe(take(1)).subscribe((res:any) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(res.body);
      a.setAttribute('download', 'prism_report.xlsx');
      a.click();
    })
  }
}
