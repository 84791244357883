import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IDistricts, IGrades, IRoles } from '@core/interfaces/ilookup';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { ToastService } from '@core/services/toast.service';
import { environment } from '@env/environment';
import { take, tap } from 'rxjs';
import * as XLSX from 'xlsx';
import { UserManagementService } from '../../../services/user-management.service';
import { Role } from '@core/enums/roles';
import { HelperService } from '@core/services/helper.service';

@Component({
  selector: 'prism-user-upload',
  templateUrl: './user-upload.component.html',
  styleUrl: './user-upload.component.scss',
})
export class UserUploadComponent {
  private toastService = inject(ToastService);
  private authService = inject(AuthService);
  private lookupService = inject(LookupService);
  private userManagementService = inject(UserManagementService);
  private helperService = inject(HelperService);
  private resourceContainer = 'resources';
  user = this.authService.getCurrentUser();
  isDistrictAdmin = this.user.roleId == Role.DistrictAdmin;
  file: any;
  arrayBuffer: any;
  fileList: any;
  roles: IRoles[] = [];
  organizations: IDistricts[] = [];
  ageGrade: IGrades[] = [];
  headers: any;
  uploadData: any[] = [];
  tableData: any[] = [];
  finalData: any[] = [];
  hasError = false;
  canUpload = false;
  fileLink = environment.uploadFileLink;
  sasToken: string = '';
  sanitizedSasToken!: SafeUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.getSasToken();
    this.getRoles();
    this.getOrganizations();
    this.getAgeGrade();
  }

  getSasToken(): void {
    this.authService
      .getStorageSasToken(this.resourceContainer)
      .pipe(
        take(1),
        tap((res: any) => (this.sasToken = res.value))
      )
      .subscribe();
  }

  generateUrlWithSasToken(blobUrl?: string) {
    if (this.sasToken) {
      const urlWithSasToken = `${blobUrl}${this.sasToken}`;
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlWithSasToken);
    }
    return '';
  }

  getRoles(): void {
    this.lookupService
      .getRoles()
      .pipe(
        take(1),
        tap((res: IRoles[]) => (this.roles = res))
      )
      .subscribe();
  }

  getOrganizations(): void {
    this.lookupService
      .getOrganizations()
      .pipe(
        take(1),
        tap((res: IDistricts[]) => (this.organizations = res))
      )
      .subscribe();
  }

  getAgeGrade(): void {
    this.lookupService
      .getGrades()
      .pipe(
        take(1),
        tap((res: IGrades[]) => (this.ageGrade = res))
      )
      .subscribe();
  }

  addFile(event: any) {
    this.hasError = false;
    this.tableData = [];
    this.headers = [];
    this.canUpload = false;
  
    const fileInput = event.target; // Capture the input element
    this.file = fileInput.files[0];
  
    if (!this.file) return; // Check if a file is selected
  
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
      this.uploadData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
  
      // Check if the uploadData exceeds 500 rows
      if (this.uploadData.length > 500) {
        this.toastService.error(
          'You can only upload up to 500 users at a time.'
        );
        return;
      }
  
      // Process each row in uploadData
      this.uploadData.forEach((row) => {
        let processed: any[] = [];
        this.headers.forEach((prop: string) => {
          let colData = {
            name: prop,
            displayValue: row[prop],
            value: row[prop],
            invalid: false,
          };
          processed.push(colData);
        });
        this.tableData.push(processed);
      });
  
      // Reset file input to allow re-uploading the same file
      fileInput.value = '';
    };
  }
  

  validate() {
    let hasError = false;
    for (var i = 0; i < this.tableData.length; i++) {
      var row = this.tableData[i];
      for (var j = 0; j < row.length; j++) {
        var column = row[j];
        column.invalid = false;
        switch (column.name) {
          case 'districtId':
            if (column.value == '' || column.value == null) {
              column.invalid = true;
            } else if (this.isDistrictAdmin) {
              if (column.value != this.user.districtId) {
                column.invalid = true;
                this.toastService.error('You cannot upload users for another district.');
              } 
            } else {
              const org = this.organizations.find((u) => u.id == column.value);
              if (!org) column.invalid = true;
            }
            break;
          case 'gradeId':
            if (column.value == '' || column.value == null) {
              column.invalid = true;
            } else {
              const ageGrade = this.ageGrade.find((u) => u.id == column.value);
              if (!ageGrade) column.invalid = true;
            }
            break;
          case 'emailName':
            if (!column.displayValue || !this.helperService.emailRegex.test(column.displayValue) || 
            column.displayValue.length > 50) {
              column.invalid = true;
            }
            else if (this.tableData.filter((r) => r[j].value == column.value).length > 1) {
              column.invalid = true;
            }
            break;
          case 'firstName':
          case 'lastName':
            if (column.value == '' || column.value == null || 
              column.value.length > 50) {
              column.invalid = true;
            }
            break;
          default:
            break;
        }
      }

      row.invalid = row.filter((r: any) => r.invalid).length > 0;
      if (row.invalid) {
        hasError = true;
      }
    }

    this.canUpload = !hasError;
    if (this.canUpload) {
      this.processFinalData(); //this shapes the data needed by the api
      this.toastService.success('File validated. Ready to be submitted');
    } else {
      this.toastService.error(
        'Please fix errors on the file before submitting.'
      );
    }
  }

  processFinalData() {
    this.finalData = [];
    this.tableData.forEach((row) => {
      let user: any = {};
      row.forEach((column: any) => {
        user[column.name] = column.value;
      });
      this.finalData.push(user);
    });
  }

  edit(row: { edit: boolean }) {
    this.canUpload = false;
    this.tableData.forEach((t) => (t.edit = false));
    row.edit = true;
  }

  upload() {
    this.finalData.forEach((user) => {
      user.roleId = Role.User; // Adding District users only.
    })
    this.userManagementService.addUsers(this.finalData).pipe(
      take(1),
      tap((res: boolean) => {
        if (res) {
          this.toastService.success('Upload successful');
          this.tableData = [];
          this.headers = [];
          this.canUpload = false;
        }
      })
    ).subscribe();
  }
}
