import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { UserManagementComponent } from './management/components/user-management/user-management.component';
import { LayoutComponent } from '@views/public/layout/layout.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { UserAddComponent } from './management/components/user-management/user-add/user-add.component';
import { ResourcesFormComponent } from './resources/components/resources-form/resources-form.component';
import { CourseComponent } from './course/course.component';
import { CourseHistoryComponent } from './courses/components/course-history/course-history.component';
import { CourseUserComponent } from './courses/components/course-user/course-user.component';
import { ReportCardsComponent } from './report/report-cards/report-cards.component';
import { ReportComponent } from './report/report.component';
import { ReportViewComponent } from './report/report-view/report-view.component';
import { DistrictManagementComponent } from './management/components/district-management/district-management.component';
import { SchoolManagementComponent } from './management/components/school-management/school-management.component';
import { userRouteGuard } from '@core/guards/user-guard.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'user-management', component: UserManagementComponent, canActivate: [userRouteGuard] },
      { path: 'district-management', component: DistrictManagementComponent, canActivate: [userRouteGuard] },
      { path: 'school-management', component: SchoolManagementComponent, canActivate: [userRouteGuard] },
      { path: 'school-management/:districtId', component: SchoolManagementComponent, canActivate: [userRouteGuard] },
      { path: 'user-add', component: UserAddComponent, canActivate: [userRouteGuard] },
      { path: 'account-settings', component: AccountSettingsComponent },
      { path: 'course/:id', component: CourseComponent },
      { path: 'courses', component: CourseUserComponent },
      { path: 'course-history', component: CourseHistoryComponent },
      { path: 'resources/external/form', component: ResourcesFormComponent },
      { path: 'resources/external/form/:id', component: ResourcesFormComponent },
      { path: 'resources/impact-video/form', component: ResourcesFormComponent },
      { path: 'resources/impact-video/form/:id', component: ResourcesFormComponent },
      { 
        path: 'report', 
        component: ReportComponent ,
        canActivate: [userRouteGuard],
        children: [
          { path: '', component: ReportCardsComponent },
          { path: 'report-cards', component: ReportCardsComponent },
          { path: 'report-view', component: ReportViewComponent }
        ]
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeaturesRoutingModule { }
