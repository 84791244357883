<div class="max-h-fit px-10 lg:px-20 pt-10">
  <div class="grid grid-cols-2 py-5">
    <div class="col-span-2 lg:col-span-1 items-center pb-4">
      <h1 class="text-dark-purple text-3xl font-bold">Schools</h1>
    </div>
    <div class="col-span-2 lg:col-span-1 pb-4 flex justify-end">
      <prism-btn 
          btnClass="bg-white text-dark-purple font-bold border-2  p-0 m-0 rounded-xl border-dark-purple"
          (click)="addSchool()">
      <i class="uil uil-plus text-dark-purple font-bold pr-1"></i
        >School(s)
      </prism-btn>
    </div>
      <div class="col-span-2 lg:col-span-1 items-center pb-4">
        <div class="w-full lg:w-3/4">
          <prism-input 
            inputType="text" 
            [control]="searchForm.get('search')"
            [required]="false"
            inputType="text"
            placeHolder="Search for School"
            [hasSearch]="true"
            (search)="searchSchools()">
          </prism-input>
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1 pb-4 flex justify-end">
        <div class="w-full lg:w-1/2">
          <prism-select-organization
            [control]="searchForm.get('organizationId')"
            placeHolder="Select Organization"
            [required]="false"
            [readOnly]="isDistrictAdmin">
          </prism-select-organization>
        </div>
      </div>
  </div>
  <prism-table [columns]="columns" 
               [colHeaders]="colHeaders" 
               [tableData]="data" 
               [isDelete]="true" 
               [isEdit]="true"
               [pageIndex]="pageIndex"
               [pageSize]="pageSize"
               [totalData]="totalData"
               (edit)="editSchool($event)"
               (pageIndexChange)="handlePageIndexChange($event)"
               (pageSizeChange)="handlePageSizeChange($event)"
               (delete)="deleteSchool($event)">
  </prism-table>
</div>

<nz-modal [(nzVisible)]="isVisible" [nzTitle]="modalTitle" nzWidth="800px" [nzBodyStyle]="{'height':'auto'}" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <form class="grid grid-cols-2 gap-x-4" [formGroup]="schoolForm">

      <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
        <prism-input
            [control]="schoolForm.get('name')"
            placeHolder="Enter school name"
            label="School Name"
            [required]="true"
            inputType="text"
            [maxLength]="50">
        </prism-input>
      </div>

      <div class="col-span-2 lg:col-span-1 items-center w-[100%] pb-4">
        <prism-select-organization
          [control]="schoolForm.get('districtId')"
          label="Organization"
          placeHolder="Select Organization"
          [required]="true"
          [readOnly]="isDistrictAdmin">
        </prism-select-organization>
      </div>
    </form>
  </ng-container>
</nz-modal>