import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Role } from '@core/enums/roles';
import { ToastService } from '@core/services/toast.service';

export const userRouteGuard: CanActivateFn = (route, state): boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const toast = inject(ToastService);
  const userType = authService.getCurrentUser().roleId;

  if (userType === Role.User) {
    toast.error('Unathorized access');
    return router.createUrlTree(['/dashboard']);
  }

  // For district and super admin, allow all routes
  return true;
};
